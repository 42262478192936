import type { FC, ReactNode } from 'react'
import { get } from 'idb-keyval'
import axios from 'axios'
import React, {
  useContext,
  useEffect,
  useReducer,
  createContext,
  useLayoutEffect,
} from 'react'
import { deepObjectCopy } from 'src/utils'

const BuyQuizContext = createContext<BuyQuizContextProps>(
  {} as BuyQuizContextProps
)

export const BuyQuizProvider: FC<
  React.PropsWithChildren<Partial<HTMLCollection & ReactNode>>
> = ({ children }) => {
  function reducer(state: BuyQuizProps, action: QuizActionTypes): BuyQuizProps {
    const { payload } = action
    const newState = deepObjectCopy(state)

    if (Array.isArray(payload)) {
      payload?.forEach((item) => {
        Object?.assign(newState, { [item?.property]: item?.value })
      })

      return { ...newState }
    }

    const { property, value } = (payload as IQuizActionPayload) ?? {}

    if (property) {
      return { ...newState, [property]: value }
    }

    return {
      ...newState,
      ...payload,
    }
  }

  const [quizState, dispatch] = useReducer(reducer, {
    ageGroup: null,
    email: null,
    giftedPerson: null,
    heOrShe: null,
    name: null,
    olderThanEighteen: null,
    priceRange: null,
    style: null,
  })

  const setDataQuizResultBySessionStorage = () => {
    const resultQuiz = window?.sessionStorage?.getItem('resultQuiz')

    if (resultQuiz) {
      const parseResultQuiz = JSON.parse(resultQuiz)

      dispatch({
        payload: parseResultQuiz,
      })
    }
  }

  useLayoutEffect(() => {
    setDataQuizResultBySessionStorage()
  }, [])

  useEffect(() => {
    if (quizState.email) {
      return
    }

    const getSessionUserInfo = async () => {
      const response = await get('fs::session')
      const person = response?.person

      if (!person?.email) {
        return
      }

      dispatch({ payload: { property: 'email', value: person.email } })

      let olderThanEighteen: boolean | null

      try {
        const clientDataResponse = await axios.post('/api/getClientData', {
          userId: person.id,
        })

        olderThanEighteen = clientDataResponse?.data?.[0]?.olderThanEighteen
      } catch (error) {
        olderThanEighteen = null
      }

      if (olderThanEighteen) {
        dispatch({
          payload: { property: 'olderThanEighteen', value: olderThanEighteen },
        })
      }

      if (!person?.givenName) {
        return
      }

      const { givenName } = person
      const familyName = person?.familyName ? ` ${person.familyName}` : ''

      const personName = `${givenName}${familyName}`

      dispatch({ payload: { property: 'name', value: personName } })
    }

    getSessionUserInfo()
  }, [quizState.email, quizState.style])

  return (
    <BuyQuizContext.Provider
      value={{
        quizState,
        dispatch,
      }}
    >
      {children}
    </BuyQuizContext.Provider>
  )
}

export const useBuyQuizContext = () => {
  return useContext(BuyQuizContext)
}
